$(document).ready(function () {
    $('#slider').slick({
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: "<div class='left'><span class='fa fa-chevron-left'></span></div>",
        nextArrow: "<div class='right'><span class='fa fa-chevron-right'></span></div>",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
//          arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
//          arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $('#realizations').slick({
        dots: false,
        infinite: true,
        centerMode: true,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: "<div class='left'><span class='fa fa-chevron-left'></span></div>",
        nextArrow: "<div class='right'><span class='fa fa-chevron-right'></span></div>",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    centerMode: false,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });





    $('.slider-text').slick({
        infinite: true,
        autoplaySpeed: 2000,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
    });
    $('.slider-text-cat').slick({
        infinite: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true
    });



    AOS.init();


});
$('#gallery-subpage,#gallery-youtube').slick({
    dots: false,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: "<div class='left'><span class='fa fa-chevron-left'></span></div>",
    nextArrow: "<div class='right'><span class='fa fa-chevron-right'></span></div>",
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                centerMode: false,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});
$('.form-body .form-control').focus(function () {
    $(this).parent().addClass('active');
});
$('.form-body .form-control').focusout(function () {
    if ($(this).val() == '') {
        $(this).parent().removeClass('active');
    }
});
$('.form-body form').on('submit', function (e) {
    flag = 0;
    $('.form-body input.form-control, .form-body textarea').each(function () {
        if ($(this).val() === '') {
            flag = 1;
            $(this).parent().removeClass('val-ok').addClass('val-err');
        } else {
            $(this).parent().removeClass('val-err').addClass('val-ok');
        }
    });
    var expression = /^([0-9+ -]{9,15})$/;
    var regex = new RegExp(expression);
    var t = $('.form-body input[name=phone]').val();
    if ($('.form-body select[name=topic]').val() == 'hide') {
        flag = 1;
        $('.form-body select[name=topic]').parent().removeClass('val-ok').addClass('val-err');
    } else {
        $('.form-body select[name=topic]').parent().removeClass('val-err').addClass('val-ok');
    }
    if (t.match(regex)) {
        $('.form-body input[name=phone]').parent().removeClass('val-err').addClass('val-ok');
    } else {
        flag = 1;
        $('.form-body input[name=phone]').parent().removeClass('val-ok').addClass('val-err');
    }
    if (flag === 1) {
        e.preventDefault();
    }
});
$('ul.dropdown-menu [data-toggle=dropdown]').on('click', function (event) {
// Avoid following the href location when clicking
    event.preventDefault();
    // Avoid having the menu to close when clicking
    event.stopPropagation();
    // If a menu is already open we close it
    //$('ul.dropdown-menu [data-toggle=dropdown]').parent().removeClass('open');
    // opening the one you clicked on
    $(this).parent().toggleClass('open');
    var menu = $(this).parent().find("ul");
    var menupos = menu.offset();
    if ((menupos.left + menu.width()) + 30 > $(window).width()) {
        var newpos = -menu.width();
    } else {
        var newpos = $(this).parent().width();
    }
    menu.css({left: newpos});
});
$('select').each(function () {
    var $this = $(this), numberOfOptions = $(this).children('option').length;
    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    var $styledSelect = $this.next('div.select-styled');
    $styledSelect.text($this.children('option').eq(0).text());
    var $list = $('<ul />', {
        'class': 'select-options'
    }).insertAfter($styledSelect);
    for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
    }

    var $listItems = $list.children('li');
    $styledSelect.click(function (e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function () {
            $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
    });
    $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        //console.log($this.val());
    });
    $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
    });
});
function goBack() {
    window.history.back();
}

x = 50;
$('.one .pillar');
//setTimeout(function () {
//    var a = 0;
//    $('.number').each(function () {
//        x = x + 50;
//        var $this = $(this),
//                countTo = $this.attr('data-count');
//        $({
//            countNum: $this.text()
//        }).animate({
//            countNum: countTo
//        },
//                {
//                    duration: x,
//                    easing: 'swing',
//                    step: function () {
//                        $this.text(Math.floor(this.countNum));
//                    },
//                    complete: function () {
//                        $this.text(this.countNum);
//                        //alert('finished');
//                    }
//
//                });
//    });
//    a = 1;
//}, 1000);

b = 1;
if ($(" .pillars").length > 0) {
    $(window).scroll(function () {
        var top_of_element = $(" .pillars").offset().top;
        var bottom_of_element = $(".pillars").offset().top + $(".pillars").outerHeight();
        var bottom_of_screen = $(window).scrollTop() + window.innerHeight;
        var top_of_screen = $(window).scrollTop();
//    console.log('----')
//    console.log(top_of_element);
//    console.log(bottom_of_element);
//    console.log(bottom_of_screen);
//    console.log(top_of_screen);

        if ((bottom_of_screen > top_of_element - 100) && (top_of_screen < bottom_of_element) && b == 1) {
            console.log('widze');
            b = 0;
            setTimeout(function () {
                var a = 0;
                $('.number').each(function () {

                    var $this = $(this),
                            countTo = $this.attr('data-count');
                    $({
                        countNum: $this.text()
                    }).animate({
                        countNum: countTo
                    },
                            {
                                duration: 1000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                    //paret = this.parent('.pillar-txt');
                                    $('.first').fadeIn();
                                    $('.first_but_second').fadeIn();
                                }

                            });
                });
                a = 1;
            }, 500);
        } else {
            $('.number').each(function () {
                pare = $(this).parents('.pillar-main');
                if ($('.pillar', pare).height() == 0)
                {
                    $(this).html(0);
                    b = 1;
                }

            });
        }
    });
}
//map style
var styledMap = [
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#1d1d1b"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-100"
            },
            {
                "lightness": "50"
            },
            {
                "gamma": "1"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#fccc25"
            },
            {
                "visibility": "on"
            }
        ]
    }
]

;
if ($('#map').length > 0) {
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        mapTypeControlOptions: {
            mapTypeIds: ['styled_map']
        },
        center: new google.maps.LatLng(52.188923, 21.082521),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: styledMap,
        zoom:10,
                zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        fullscreenControl: true

    });
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(52.188923, 21.082521),
        map: map,
        animation: google.maps.Animation.DROP,
        icon: '/themes/udzwigniemy/assets/img/marker.png',
    });
    var infoWindow = new SnazzyInfoWindow({
        marker: marker,
        content: '<div class="business-card2">' + $('.business-card').html() + '</div>',
        placement: 'right',
        offset: {
            left: '30px',
            top: '0px'
        },
        showCloseButton: true,
        disableAutoPan: true,
        closeOnMapClick: true,
        padding: '30px',
        backgroundColor: 'rgba(47, 47, 47, 0.95)',
        border: false,
        borderRadius: '0px',
        shadow: false,
    });
    infoWindow.open();
}
$(document).ready(function () {
    $('.venobox').venobox(
            {
                border: '40',
                bgcolor: 'rgba(53, 53, 53, 0.9)',
                overlayColor: 'rgba(47, 47, 47, 0.99)',
                spinColor: '#fdb825',
                framewidth: '1000px',
                spinner: 'cube-grid',
                arrowsColor: '#fdb825',
                closeColor: '#fdb825',
                numeratio: 'true',
                numerationColor: '#fdb825',
                numerationPosition: 'top',
            });
    $('.venobox_you').venobox(
            {
                border: '40',
                bgcolor: 'rgba(53, 53, 53, 0.9)',
                overlayColor: 'rgba(47, 47, 47, 0.99)',
                framewidth: '800px',
                frameheight: '500px',
                spinColor: '#fdb825',
                spinner: 'cube-grid',
                arrowsColor: '#fdb825',
                closeColor: '#fdb825',
                numeratio: 'true',
                numerationColor: '#fdb825',
                numerationPosition: 'top',
            });
});
$(function () {

    $('.nav > li > ul > li a .fa-chevron-down').parent().parent().addClass('dropdown-submenu');
    $('[data-toggle="tooltip"]').tooltip({html: true});
})
var $myForm = $('#contactFormJr');
//alert($myForm);

function contact_form_submit()
{


    if (!$myForm[0].checkValidity()) {

        $myForm.find(':submit').click();
    }

    // $('#contactFormJr').submit();

}


$('.Kontakt a').click(function () {
// alert($('#kontakt').offset().top);
    $('html, body').animate({
        scrollTop: ($('#kontakt').offset().top - 200)
    }, 800);
    return false;
});

if ((window.location.href).indexOf('#kontakt_k') > -1)
{

  //alert($('#kontakt').offset().top);

    $('html, body').animate({
        scrollTop: ($('#kontakt').offset().top - 3250 )
    }, 800);



}
